
const setDeviceDimensions = (dimensions={width:'412px',height:'832px'})=>{
    return(
        {
            type:'SET_DEVICE_DIMESIONS',
            payload: dimensions                       
        }         
    )
}

export default setDeviceDimensions;
 