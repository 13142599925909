
const deviceOrientationReducer = (state='',action)=>{
    if(action.type === 'SET_DEVICE_ORIENTATION'){
        return action.payload
    }
    else{
        return state;
    }
}

export default deviceOrientationReducer;