import React from "react";
import Menu from "./Menu";

const CVResume = () => {
  return (
    <div className="fc jc aic cv_resumeDiv">
      <Menu />
      <div className="fc fdv aic cv_resumeContentDiv">
        <div className="fc fdv aic cv_resumeContentDiv1">
          <h3>
            John Teye Brown |{" "}
            <span className="full_stack_span">Full Stack Developer</span>
          </h3>
          <p>
            Email: <a href="mailto:teyeologo@gmail.com">teyeologo@gmail.com</a>
          </p>
          <hr className="solid" />
        </div>
        <div className="fc fdv aic cv_resumeContentDiv2">
          <h4>Summary</h4>
          <p style={{ fontWeight: "100" }}>
            Versatile full-stack developer with over 4 years of experience
            developing, testing, and maintaining scalable web applications using
            React.js, Node.js, MongoDB, and Google Cloud. Highly skilled in
            writing unit and integration tests with Jest and React Testing
            Library, along with Python’s Unittest framework. Adept at both
            front-end and back-end development, debugging, and collaborating
            with cross-functional teams to deliver high-quality, user-friendly
            products. Passionate about continuous learning and staying updated
            with the latest web development trends.
          </p>
          <hr className="solid" />
        </div>
        <div className="fc fdv aic cv_resumeContentDiv3">
          <h4>Technical SKills</h4>
          <ul>
            <li>
              <b>Languages & Frameworks</b>: JavaScript (ES6+), TypeScript,
              React.js, Redux, Node.js, Express.js, Next.js, HTML, CSS, Jest,
              React Testing Library, Python (Unittest)
            </li>
            <li>
              <b>Databases</b>: MongoDB, MySQL, PostgreSQL, Firebase
            </li>
            <li>
              <b>Cloud</b>: Google Cloud (App Engine), Microsoft Azure, AWS
            </li>
            <li>
              <b>Tools</b>: GitHub, Docker, Kubernetes, RESTful APIs, Webpack,
              Bootstrap, Jenkins, JIRA, Sentry SDK, ClickHouse, Matomo, Plotly,
              D3.js
            </li>
          </ul>
          <hr className="solid" />
        </div>
        <div className="fc fdv afs cv_resumeContentDiv4">
          <h4>Experience</h4>
          <p>Contractor | Full Stack Developer | RBC | Nov 2021 - Aug 2024</p>
          <ul>
            <li>
              leveraging React.js for dynamic front-end interfaces and Node.js
              for back-end logic, ensuring responsiveness and performance.
            </li>
            <li>
              Played a key role in initial designs using Figma, collaborating
              with the design team and stakeholders to implement user-centered
              features.
            </li>
            <li>
              Instrumental in writing unit and integration tests using Jest and
              React Testing Library, ensuring high-quality and bug-free code,
              along with setting up Sentry SDK for bug monitoring and reporting.
            </li>
            <li>
              Built RESTful APIs and integrated external services such as Sentry
              for bug tracking, ClickHouse and Matomo for analytics, and Plotly
              and D3.js for data visualizations.
            </li>
            <li>
              Spearheaded project requirement gathering and estimates for a
              major quants project, translating business goals into technical
              specifications while managing timelines and budgets.
            </li>
            <li>
              Maintained project dependencies by updating package.json and
              requirements.txt files, ensuring the projects were aligned with
              the latest technologies.
            </li>
            <li>
              Dedicated to learning new technologies through enterprise-level
              courses and staying updated on cutting-edge trends, such as data
              structures, algorithms, design patterns, and security best
              practices.
            </li>
            <li>
              Strong collaboration with teams across departments, making sure
              all bug reports and fixes were handled efficiently, improving
              product quality and customer satisfaction.
            </li>
          </ul>
          <br />
          <b>Key Achievements:</b> <br />
          <ul>
            <li>
              Built and deployed complex, data-driven dashboards using Plotly
              and D3.js for visualization purposes.
            </li>
            <li>
              Instrumental in bug tracking and debugging via Sentry SDK, leading
              to a significant reduction in critical errors.
            </li>
            <li>
              Integrated external APIs and services, improving functionality and
              expanding project capabilities.
            </li>
            <li>
              Regularly participated in project planning and contributed to team
              discussions on web development best practices.
            </li>
          </ul>
          <p>
            Contractor | Full Stack Developer | Telus | September 2021 -
            November 2021
          </p>
          <ul>
            <li>
              Developed web and mobile interfaces for in-store systems using
              React.js, Next.js, and Node.js, enabling smoother customer
              purchasing experiences.
            </li>
            <li>
              Wrote unit and integration tests using Jest and React Testing
              Library to maintain code quality and system stability.
            </li>
            <li>
              Led backend development using Node.js and Express to build RESTful
              APIs, while handling server-side logic and database interactions.
            </li>
            <li>
              Deployed applications on Google Cloud Platform (GCP) using App
              Engine, ensuring scalability and high availability.
            </li>
            <li>
              Focused on maintaining clean and efficient code, frequently
              updating the package.json files and ensuring all dependencies were
              kept up-to-date with the latest versions.
            </li>
          </ul>
          <br />
          <b>Key Achievements:</b>
          <br />
          <ul>
            <li>
              Played a pivotal role in a small team, delivering critical backend
              and server-side logic on time.
            </li>
            <li>
              Deployed complex web applications on Google Cloud, ensuring smooth
              operation and high uptime.
            </li>
          </ul>
          <p>
            Founder & Lead Full Stack Developer | ilearnstem.com,
            ilearnstemcards.com | Aug 2024 - Present
          </p>
          <ul>
            <li>
              Led the full-stack development of e-learning platforms, using
              React.js for front-end, Node.js/Express for back-end, and MongoDB
              for database management.
            </li>
            <li>
              Designed, developed, and deployed features that allowed users to
              create, share, and review educational flashcards, enhancing user
              engagement and learning outcomes.
            </li>
            <li>
              Continuously maintained project dependencies by updating relevant
              files and adopting the latest frameworks and libraries, ensuring
              the platform's longevity and stability.
            </li>
            <li>
              Deployed applications on Google Cloud, utilizing its services for
              optimized performance and scalability.
            </li>
          </ul>
          <br />
          <p>
            Assistant Professor of Math & Natural Sciences | Higher Colleges of
            Technology, UAE | Aug 2016 - Jul 2020
          </p>
          <ul>
            <li>
              Taught courses on Python, C++, and web development, fostering the
              next generation of programmers and engineers.
            </li>
            <li>
              Supervised 13 capstone projects focused on AI, embedded systems,
              and industrial robotics, encouraging students to innovate and
              apply theoretical knowledge to real-world problems.
            </li>
          </ul>
          <hr className="solid" />
          <h4>Education</h4>
          <ul>
            <li>
              Ph.D. Computational Mathematics | Louisiana Tech University,
              Ruston, Louisiana, USA
            </li>
            <li>M.S. Mathematics and Statistics | Louisiana Tech University</li>
            <li>M.S. Mechanical Engineering | Louisiana Tech University</li>
          </ul>
          <br />
          <hr className="solid" />
          <b>Soft Skills</b>
          <br />
          <span>
            My experiences working with multiple teams in various settings shows
            that I:
          </span>
          <br />
          <ul>
            <li>
              Have excellent problem-solving, debugging, and troubleshooting
              abilities demonstrated across various projects.
            </li>
            <li>
              Have strong communication, collaboration, and time management
              skills, effectively working with cross-functional teams to meet
              project deadlines.
            </li>
            <li>
              Am passionate about learning new technologies and consistently
              improving existing skill sets through online courses, reading
              books, and exploring new tools and frameworks.
            </li>
          </ul>
          <hr className="solid" />
          <b>Portfolio Website</b>
          <a href="https://teyebrown.com">teyebrown.com</a>
        </div>
      </div>
    </div>
  );
};

export default CVResume;
