import {combineReducers} from 'redux';

import deviceDimesionsReducer from './deviceDimesionsReducer';
import deviceOrientationReducer from './deviceOrientationReducer';

const allReducers = combineReducers({
    deviceDimesionsReducer:deviceDimesionsReducer,
    deviceOrientationReducer:deviceOrientationReducer,
     
   });

export default allReducers;