import React from "react";


const Publications = () => {



    return (
        <div id='publicationsPage'>
            <div className="fc jc publicationsIntroDiv">
                <p id='publicationsIntro' className="description1 fc jc">
                    Welcome to the my publications page. Most of the publications here include research/projects using:
                    Convolutional Neural Networks (Deep Learning), Medical Image Segmentation,Embedded Systems Robotics,
                    Micro Electromechanical Systems (MEMs), Numerical PDE Control Theory
                </p>
            </div>
            <br />
            <br />

            <div className="w3-responsive w3-mobile" id="publicationsTable">
                <table className="w3-table w3-hoverable w3-dark-grey" id="publicationsTable1">
                    <thead className="w3-blue">
                        <tr >
                            <th>Title</th>
                            <th>Cited by</th>
                            <th>Publication Year</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="w3-hover-white">
                            <td>EEG Solar Race Car</td>
                            <td>0</td>
                            <td>2020</td>
                            <td><a className="w3-button w3-light-grey" target="_blank" rel="noreferrer"
                                href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:9yKSN-GCB0IC"
                                style={{ textDecoration: 'none', width: '150px' }}>
                                Article Link</a>
                            </td>
                        </tr>
                        <tr className="w3-grey w3-hover-light-grey">
                            <td>Biomedical Signal Processing and Artificial Intelligence in Healthcare. Chapter 5: Deep EEG- Deep
                                Learning in Biomedical Signal Processing with EEG Applications
                            </td>
                            <td>0</td>
                            <td>2020</td>
                            <td><a className="w3-button w3-dark-grey" target="_blank" rel="noreferrer"
                                href="https://www.sciencedirect.com/book/9780128189467/biomedical-signal-processing-and-artificial-intelligence-in-healthcare"
                                style={{ textDecoration: 'none', width: '150px' }}>
                                Book Link</a>
                            </td>
                        </tr>
                        <tr className="w3-hover-white">
                            <td>The Application of Deep Learning to Quantify SAT/VAT in Human Abdominal Area</td>
                            <td>2</td>
                            <td>2019</td>
                            <td>
                                <a className="w3-button w3-light-grey" target="_blank" rel="noreferrer"
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:Y0pCki6q_DkC"
                                    style={{ textDecoration: 'none', width: '150px' }}>
                                    Article Link
                                </a>
                            </td>
                        </tr>
                        <tr className="w3-grey w3-hover-light-grey">
                            <td>Deep Learning AI Application to an EEG Driven BCI Smart Wheelchair</td>
                            <td>14</td>
                            <td>2019</td>
                            <td>
                                <a className="w3-button w3-dark-grey" target="_blank" rel="noreferrer"
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:u5HHmVD_uO8C"
                                    style={{ textDecoration: 'none', width: '150px' }}>
                                    Article Link
                                </a>
                            </td>
                        </tr>
                        <tr className="w3-hover-white">
                            <td>Autonomous Robot Fish for Underwater Pollution Detection </td>
                            <td>2</td>
                            <td>2018</td>
                            <td>
                                <a className="w3-button w3-light-grey" target="_blank" rel="noreferrer"
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:Tyk-4Ss8FVUC"
                                    style={{ textDecoration: 'none', width: '150px' }}>
                                    Article Link
                                </a>
                            </td>
                        </tr>
                        <tr className="w3-grey w3-hover-light-grey" >
                            <td>A Prototype of Autonomous Police Car To Reduce Fatal Accidents in Dubai</td>
                            <td>4</td>
                            <td>2018</td>
                            <td>
                                <a className="w3-button w3-dark-grey" target="_blank" rel="noreferrer"
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:IjCSPb-OGe4C"
                                    style={{ textDecoration: 'none', width: '150px' }}>
                                    Article Link
                                </a>
                            </td>
                        </tr>
                        <tr className="w3-hover-white">
                            <td>The Search for an Optimal means of determining the minmax control parameter using sensitivity analysis</td>
                            <td></td>
                            <td>2012</td>
                            <td>
                                <a className="w3-button w3-light-grey" target="_blank" rel="noreferrer"
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:UeHWp8X0CEIC"
                                    style={{ textDecoration: 'none', width: '150px' }}>
                                    Dissertation Link
                                </a>
                            </td>
                        </tr>
                        <tr className="w3-grey w3-hover-light-grey">
                            <td>Riccati Conditioning and Sensitivity for a MinMax Controlled Cable-Mass System </td>
                            <td></td>
                            <td>2008</td>
                            <td>
                                <a className="w3-button w3-dark-grey" target="_blank" rel="noreferrer"
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:qjMakFHDy7sC"
                                    style={{ textDecoration: 'none', width: '150px' }}>
                                    Article Link
                                </a>
                            </td>
                        </tr>
                        <tr className="w3-hover-white">
                            <td>Intermetallic Effects of Electroplated Lead-Free Solder Bumps Using a Novel Single Chamber Electroplating Process for Large Diameter Wafers </td>
                            <td></td>
                            <td>2007</td>
                            <td>
                                <a className="w3-button w3-light-grey" target="_blank" rel="noreferrer"
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=6bau78kAAAAJ&citation_for_view=6bau78kAAAAJ:zYLM7Y9cAGgC"
                                    style={{ textDecoration: 'none', width: '150px' }}>
                                    Article Link
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Publications;