import React from "react";
import { Link } from "react-router-dom";


const Resume = ()=>{

    return(
        <div className='w3-row' id='ResumePage'>


            <h1 className='w3-row' style={{color:"#ffffff",width:'100%',textAlign:'center'}}> Dr. John Teye Brown, PhD </h1>
            <h6 style={{textAlign:'center',color:"#ffffff"}}>teyeologo@gmail.com</h6>

        <div className="w3-hover-grayscale w3-card-4 w3-container" id="resume">
                <Link to="/cv"> <h4 className="w3-button w3-white w3-hover-blue">&larr; back</h4></Link>
            <h3 className='w3-row' style={{color:"#ffffff",width:'100%',textAlign:'center',marginTop:'0px'}}> Profile Summary </h3>

            <div className="w3-row resumeTable" >
                <div className="w3-col s12 m6 resumeTableColumn">
                    <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                        <h4>Tech Skills and Education</h4>
                    </div>
                    <ul className="w3-row resumeTableList">
                        <li>Javascript, ReactJS, React Native, Redux, HTML5, Bootstrap5, CSS</li>
                        <li>NodeJS, Python, C# .Net MVC, Java, MongoDB, PostgreSQL, <br/>REST API, GraphQL</li>
                        <li>Git, Github, Bitbucket</li>
                        <li>PhD Computational Analysis & Modeling</li>
                    </ul>

                </div>

                <div className="w3-col s12 m6 resumeTableColumn">
                    <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                        <h4>Qualitative</h4>
                    </div>
                    <ul className="w3-row resumeTableList">
                        <li>Team leader and team player</li>
                        <li>Strong communication skills</li>
                        <li>Fast and Independent thinker</li>
                        <li>Highly capable of multi-tasking</li>
                        <li>Greatest strength: fast learner</li>
                        <li>Life long learner</li>
                    </ul>
                </div>
            </div>

            <h3 className='w3-row' style={{color:"#ffffff",width:'100%',textAlign:'center',marginTop:'50px'}}> Technical Skills </h3>
            <div className="w3-row resumeTable" >
                <div className="w3-col s12 m6 resumeTableColumn">
                     <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                         <h4>Backend, Databases and Languages</h4>
                     </div>

                    

                    <ul className="w3-row resumeTableList">
                        <li>NodeJS and ExpressJS, REST API (4 years) </li>
                        <li> Django (Beginner) </li>
                        <li> Java (Intermediate)</li>
                        <li> MongoDB & MongooseJS, MySQL, PostgreSQL</li>
                        <li> Python</li>
                        <li> C#</li>
                    </ul>
                </div>

                <div className="w3-col s12 m6 resumeTableColumn">
                     <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                         <h4>Frontend and Mobile</h4>
                     </div>
                     <ul className="w3-row resumeTableList">
                        <li>Javascript (3 years) </li>
                        <li>ReactJS and Redux (2 years) </li>
                        <li> Bootstrap5 and w3.css </li>
                        <li> React Native</li>
                        <li> HTML5, CSS (4+ years)</li> 
                    </ul>
                   
                </div>

            </div>

            <div className="w3-row resumeRow" >
               <div className="w3-col s12">
                <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                         <h4>Tech Stack and Frameworks</h4> 
                </div>
                    <ul className="w3-row resumeTableList">
                        <li>MongoDB, ExpressJS, ReactJS, NodeJS (MERN)</li>
                        <li>C# .NET6 with PostgreSQL</li>
                        <li>Python and DJango</li>
                    </ul>
                </div>
            </div>

            <div className="w3-row resumeTable" >
                <div className="w3-col s12 m6 resumeTableColumn">
                    <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                        <h4>Tech Certificates (coursera.org) </h4>
                    </div>
                    <h4><b>Machine Learning, AI</b></h4>
                    <ul className="w3-row resumeTableList">
                         <li>Tensorflow in Practice <b>Specialization</b> (completed)</li>
                        <li>Deep Learning <b>Specialization</b> (completed)</li>
                        <li>Reinforcement Learning <b>Specialization</b> (ongoing 2/4 courses completed)</li>                        
                    </ul>

                </div>

                <div className="w3-col s12 m6 resumeTableColumn">
                    <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                        <h4>Tech Certificates (coursera.org) </h4>
                    </div>
                    <h4><b>Other</b></h4>

                    <ul className="w3-row resumeTableList">
                        <li>Blockchain <b>Specialization</b> (completed)</li>
                        <li>Microsoft Azure Fundamentals AZ-900 Examp Prep <br/> <b>Specialization</b> (course 1 of 4)</li>
                    </ul>
                </div>

            </div>

            <div className="w3-row resumeTable">
                <div className="w3-col s12 m4 resumeTableColumn">
                     <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                         <h4>General Software skills</h4>
                     </div>

                     <ul className="w3-row resumeTableList">
                         <li>Windows, Linux, MacOS</li>
                         <li>Microsoft Office</li> 
                     </ul>
                </div>

                <div className="w3-col s12 m4 resumeTableColumn">
                     <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                         <h4>AI & Game skills</h4>
                     </div>

                     <ul className="w3-row resumeTableList">
                         <li>Tensorflow</li>
                         <li>Keras</li>
                         <li>BabylonJS</li>
                         <li>Unity (Beginner)</li>
                         <li>Unreal Engine (Beginner)</li>
                     </ul>
                </div>

                <div className="w3-col s12 m4 resumeTableColumn">
                     <div className="w3-row resumeTableHeader" style={{width:"100%"}}>
                            <h4>Other Software skills and tools</h4>
                     </div>

                     <ul className="w3-row resumeTableList">
                         <li>Git and Github</li>
                         <li>Docker (Beginner)</li>
                         <li>GraphQL</li>
                     </ul>
                </div>

            </div>

            <h3 className='w3-row' style={{color:"#ffffff",width:'100%',textAlign:'center',marginTop:'50px'}}> Experience </h3>

            <div className="w3-row resumeRow">
                <div className="w3-col s12 m8">
                    <h4><b>Full Stack Developer (Lead) </b>- <a href="https://www.ilearnstem.com" target="_blank" rel="noreferrer">iLearnstem.com</a></h4>

                    <ul className="w3-row resumeTableList">
                         <li>Built Frontend with React, Redux and w3.css</li>
                         <li> Built Backend with NodeJS, ExpressJS, and socket.io</li>
                         <li>Built Database with MongoDB and MongooseJS</li>
                     </ul>
                </div>
                <div className="w3-col s12 m4">
                    <h4>08/2020 - present</h4>
                </div>
            </div>


            <div className="w3-row resumeRow">
                <div className="w3-col s12 m8">
                    <h4><b>Full Stack Developer (Lead)</b> - <a href="https://www.ilearnstemlab.com" target="_blank" rel="noreferrer">iLearnstemLab.com</a></h4>

                    <ul className="w3-row resumeTableList">
                         <li>Built Frontend with React, Redux and w3.css</li>
                         <li> Built Backend with NodeJS, ExpressJS, and socket.io</li>
                         <li> 3D Graphics with BabylonJS</li>
                         <li>Built Database with MongoDB and MongooseJS</li>
                     </ul>
                </div>
                <div className="w3-col s12 m4">
                    <h4>08/2020 - present</h4>
                </div>
            </div>


            <div className="w3-row resumeRow">
                <div className="w3-col s12 m8">
                    <h4><b>Project Supervisor</b> - Higher Colleges of Technology, UAE</h4>

                    <ul className="w3-row resumeTableList">
                         <li>Supervised Capstone Design Projects</li>

                         13 Groups in 
                         <li> Artificial Intelligence (Deep Learning)</li>
                         <li> Embedded Systems</li>
                         <li> Mechatronics with Python, NodeJS and C++</li>
                     </ul>
                </div>
                <div className="w3-col s12 m4">
                    <h4>08/2016 - 07/2020</h4>
                </div>
            </div>



            <div className="w3-row resumeRow">
                <div className="w3-col s12 m8">
                    <h4><b>Assistant Professor of Math and Engineering</b> - Higher Colleges of Technology, UAE</h4>

                    <ul className="w3-row resumeTableList">
                        Pertinent courses taught:

                         <li>Introduction to Computer Programming - Python and C++</li>
                         <li> Intelligent Systems - Python with OpenCV</li>
                         <li> Industrial Robotics (Python, NodeJS and C++)</li> 
                     </ul>
                </div>
                <div className="w3-col s12 m4">
                    <h4>08/2016 - 07/2020</h4>
                </div>
            </div>

            <h3 className='w3-row' style={{color:"#ffffff",width:'100%',textAlign:'center',marginTop:'50px'}}> Education </h3>
            <div className="w3-row resumeRow">
                <div className="w3-col s12">
                    <h4> (Louisiana Tech University, USA)</h4>

                    <ul className="w3-row resumeTableList">
                        Pertinent courses taught:

                         <li>PhD Computational Analysis and Modeling (Specialization in Numerical PDE Control Theory) - <b>05/2012</b></li>
                         <li> MS Mathematics and Statistics - <b>08/2011</b></li>
                         <li> MS Mechanical Engineering - <b>05/2008</b></li> 
                     </ul>
                </div>
                
            </div>

            <h3 className='w3-row' style={{color:"#ffffff",width:'100%',textAlign:'center',marginTop:'50px'}}> Publications </h3>

            <div className="w3-row resumeRowLast">
             
                    <h4><b>Book Chapter</b> (Louisiana Tech University, USA)</h4>

                    <b><a href = "https://www.elsevier.com/books/biomedical-signal-processing-and-artificial-intelligence-in-healthcare/zgallai/978-0-12-818946-7"> "Biomedical Signal Processing and Artificial Intelligence in Healthcare". Chapter 5: Deep EEG - Deep 
                        Learning in Biomedical Signal Processing with EEG Applications.</a></b><br/>
                        Developmenents in Biomedical Engineering and BioElectronics. Authors: Dr. J. Teye Brown and Dr. Walid Zgallai.              
                
            </div>

        </div>
            
        </div>
    )
}

export default Resume;