const AboutMe = () => {
  return (
    <div className="fc fdv jc aic aboutUsDiv" style={{ width: "100%" }}>
      <div className="fc fdv aic aboutCEOJohnDiv1 w3-card-4 aboutUsCard">
        <h4>
          <b>
            A Journey of Mathematics, Engineering, and Software Development
            across Four Continents
          </b>
        </h4>
        <p>
          Dr. Brown is a dedicated educator, accomplished academic, and
          experienced software developer who has navigated a unique path that
          spans academia, industry, and global teaching. With a PhD in
          Computational Mathematics and over 13 years as a faculty member in
          Mathematics and Engineering, he has made his mark on four continents,
          educating students and driving forward innovative solutions in STEM
          education. Beyond the lecture halls, Dr. Brown has accumulated more
          than three years of hands-on experience in the tech industry as a
          full-stack software developer, bringing his academic expertise to life
          in the fast-paced world of software engineering.
        </p>
        <p>
          At the heart of his journey is a passion for education—especially in
          the areas of science, technology, engineering, and mathematics (STEM).
          He is deeply committed to using his unique combination of skills to
          develop software that enhances the learning experience, making complex
          subjects more accessible to students of all backgrounds. As a
          co-founder of an edtech startup, he is now focused on revolutionizing
          how students engage with core STEM subjects, building platforms that
          inspire, empower, and challenge learners globally.
        </p>
      </div>
      <div className="fc fdv aic aboutCEOJohnDiv2 w3-card-4 aboutUsCard">
        <h4>
          <b>Academic Expertise in Computational Mathematics and Engineering</b>
        </h4>
        <p>
          Dr. Brown's academic career began with a passion for mathematics,
          which later evolved into a specialization in computational analysis
          and modeling. His research during his PhD, along with his extensive
          teaching experience, allowed him to explore a wide range of topics,
          including advanced mathematical theories, mechanical systems modeling,
          and computational algorithms. Throughout his career as a faculty
          member, Dr. Brown has taught subjects such as Physics, Chemistry,
          Mathematics, and Engineering at both the high school and university
          levels. He has taught and led academic initiatives across the United
          States, Guyana, the Asian Pacific, and the Middle East, enriching his
          pedagogical methods by drawing from diverse educational environments.
        </p>
        <p>
          His academic roles as a Mathematics Instructor and Assistant Professor
          have enabled him to work with students from a variety of backgrounds.
          Whether it was guiding young high school students in Guyana through
          the complexities of physics or mentoring engineering undergraduates in
          the United Arab Emirates, Dr. Brown's approach has always been
          centered on making difficult concepts accessible and exciting. His
          time teaching at institutions in Florida, Louisiana, the Marshall
          Islands, and Dubai has allowed him to see firsthand the gaps in STEM
          education across different regions, and how technology can play a
          pivotal role in closing those gaps.
        </p>
      </div>

      <div className="fc fdv aic aboutCEOJohnDiv3 w3-card-4 aboutUsCard">
        <h4>
          <b>Industry Expertise: Full Stack Software Development</b>
        </h4>
        <p>
          In addition to his extensive academic career, Dr. Brown has embraced
          the challenges of the software development industry. Over the past
          three years, he has worked as a full-stack software engineer, gaining
          in-depth knowledge of modern web technologies. His expertise in the
          MERN stack (MongoDB, Express.js, React.js, and Node.js) has allowed
          him to lead and contribute to the development of robust, scalable
          applications, while also ensuring intuitive user experiences.
        </p>
        <p>
          His professional experience extends beyond technical development; he
          has also led software projects, designed and implemented efficient
          back-end solutions, and worked on improving the search engine
          visibility of web applications. These industry experiences have
          provided him with valuable insight into the modern technological
          landscape, equipping him with the skills to bridge the gap between
          academic knowledge and practical application.
        </p>
        <p>
          This unique combination of industry and academic expertise gives Dr.
          John a distinct advantage in edtech innovation. He understands not
          only the complex theories behind STEM disciplines but also the
          technical infrastructure required to deliver effective educational
          solutions at scale.
        </p>
      </div>

      <div className="fc fdv aic aboutCEOJohnDiv4 w3-card-4 aboutUsCard">
        <h4>
          <b>Passion for STEM Education and EdTech Innovation</b>
        </h4>
        <p>
          Dr. Brown's passion for education is evident in his lifelong
          commitment to making STEM subjects more engaging and accessible. His
          current focus is on combining his deep academic knowledge with his
          software development skills to create innovative learning platforms.
          One of his most notable projects is his work as a co-founder of an
          edtech startup that seeks to reshape how students interact with STEM
          topics.
        </p>
        <p>
          Through this venture, Dr. Brown is developing a series of adaptive,
          personalized tools designed to help students master physics,
          chemistry, mathematics and Engineering. The platform he is building
          offers not just learning modules, but also interactive quizzes,
          digital flashcards, and opportunities for learners to compete with
          others globally, tracking their progress and ranking across different
          subjects. His vision is to create a dynamic, interactive environment
          where students can engage deeply with STEM subjects, whether they are
          practicing fundamentals or exploring more advanced topics.
        </p>
        <p>
          Dr. Brown is particularly interested in leveraging modern technology,
          such as machine learning and data-driven insights, to tailor
          educational experiences to the unique needs of each learner. His
          long-term goal is to build software that adapts to each student's pace
          and learning style, ensuring they are both challenged and supported
          throughout their educational journey. By combining the best practices
          from his years of teaching with cutting-edge technological tools, he
          is working to build a platform that not only teaches but inspires.
        </p>
      </div>

      <div className="fc fdv aic aboutCEOJohnDiv5 w3-card-4 aboutUsCard">
        <h4>
          <b>A Vision for the Future</b>
        </h4>
        <p>
          Dr. Brown's journey is far from over. As a thought leader in both
          education and technology, he envisions a world where STEM education is
          more accessible, effective, and enjoyable for students from all walks
          of life. His diverse experiences—spanning four continents and multiple
          industries—have shaped his understanding of global educational
          challenges, and he is eager to continue creating innovative solutions
          that can have a lasting impact on how STEM subjects are taught.
        </p>
        <p>
          In the years to come, Dr. Brown aims to further refine the ways
          technology can be used to support personalized education, fostering a
          generation of learners who are well-equipped to tackle the challenges
          of tomorrow. His commitment to blending academic rigor with practical
          innovation ensures that he will continue to be a driving force in the
          future of STEM education, empowering students around the world to
          achieve their full potential.
        </p>
        <p></p>
      </div>
    </div>
  );
};

export default AboutMe;
