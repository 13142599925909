import React from "react";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();

  return (
    <div
      className="w3-modal menuModal w3-animate-zoom"
      id="menuModal"
      style={{ display: "none" }}
    >
      <div className="w3-modal-content menuModalContent fc fdv">
        <button
          className="w3-button"
          onClick={() => {
            navigate("/projects");
          }}
        >
          Projects
        </button>
        <button
          className="w3-button"
          onClick={() => {
            navigate("/cv_resume");
          }}
        >
          CV/Resume
        </button>
        <button
          className="w3-button"
          onClick={() => {
            navigate("/aboutme");
          }}
        >
            About Me
        </button>
        <button
          className="w3-button"
          onClick={() => {
            document.getElementById("menuModal").style.display = "none";
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Menu;
