
const setDeviceOrientation = (orientation='portrait')=>{
    return(
        {
            type:'SET_DEVICE_ORIENTATION',
            payload: orientation                      
        }         
    )
}

export default setDeviceOrientation
 