import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Blog = () => {
    const location = useLocation()
    return (
        <div className='fc fdv aic jc' id='blogPage'>
            <h1>Not Yet Ready!</h1>
            <Link to={(location.state && location.state.from) || "/"} style={{ textDecoration: 'none', position: 'absolute', bottom: '50px' }}>
                <button className='w3-button w3-white w3-padding-16'>Back</button></Link>
        </div>
    )
}

export default Blog;