import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import setDeviceDimensions from "./actions/setDeviceDimensions";
import setDeviceOrientation from "./actions/setDeviceOrientation";
import Navbar from "./SmallComponents/Navbar";
import Teye from "./MainComponents/Teye";
import CVResume from "./MainComponents/CV_Resume";
import Projects from "./MainComponents/Projects";
import Blog from "./MainComponents/Blog";
import Vlog from "./MainComponents/Vlog";
import Publications from "./SmallComponents/Publications";
import Resume from "./MainComponents/Resume";
import AboutMe from "./MainComponents/Aboutme";

function App() {
  //const deviceDimensions = useSelector(state => state.deviceDimesionsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    var root = document.documentElement;
    root.style.setProperty("--screen-x", `${window.screen.availWidth}px`);
    root.style.setProperty("--screen-y", `${window.screen.availHeight}px`);
    const setNewHeight = () => {
      root.style.setProperty("--screen-x", `${window.screen.availWidth}px`);
      root.style.setProperty("--screen-y", `${window.screen.availHeight}px`);
    };

    window.addEventListener("resize", setNewHeight);
    return () => window.removeEventListener("resize", setNewHeight);
  }, []);

  useEffect(() => {
    dispatch(
      setDeviceDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    );
    dispatch(
      setDeviceOrientation(
        window.innerWidth < window.innerHeight ? "portrait" : "landscape"
      )
    );
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Teye />}></Route>
        <Route exact path="/cv_resume" element={<CVResume />}></Route>
        <Route exact path="/projects" element={<Projects />}></Route>
        <Route exact path="/blog" element={<Blog />}></Route>
        <Route exact path="/vlog" element={<Vlog />}></Route>
        <Route exact path="/publications" element={<Publications />}></Route>
        <Route exact path="/resume" element={<Resume />}></Route>
        <Route exact path="/aboutme" element={<AboutMe />}></Route>
        <Route path="/*" element={<Teye />} />
      </Routes>
    </>
  );
}

export default App;
