
export const toggleDropDown = id => {
    const div = document.getElementById(id)
    div.style.display = div.style.display === 'none' ? "block" : "none"
}

export const show = id => {
    document.getElementById(id).style.display = "block";
}

