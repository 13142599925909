import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className="fc jsb aic" id="topBar">
      <img
        src="https://ik.imagekit.io/0x95zrerf/teye-brown/JTB.svg?updatedAt=1722230218921"
        className="w3-hover-sepia"
        alt="logo"
        id="logoImage"
        onClick={() => {
          navigate("/");
        }}
      />
      <div className="fc aic topBarLinks">
        <span
          className="w3-button w3-hover-sepia w3-text-teal"
          id="hello_world"
          onClick={() => {
            navigate("/");
          }}
        >
          {"<Hello World />"}
        </span>

        <span
          className="w3-button w3-right w3-hover-sepia"
          onClick={() => {
            document.getElementById("menuModal").style.display = "block";
          }}
        >
          Menu
        </span>
        {/* <span
              className="w3-button w3-right w3-hide-large"
              onClick={showDropDownMenu}
            >
              <span id="mobileNavButton">&#9776;</span>
            </span> */}
      </div>
    </div>
  );
};

export default Navbar;
