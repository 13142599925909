
const deviceDimesionsReducer = (state={width:'',height:''},action)=>{
    if(action.type === 'SET_DEVICE_DIMESIONS'){
        return action.payload
    }
    else{
        return state;
    }
}

export default deviceDimesionsReducer;