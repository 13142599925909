import React from "react";
import Menu from "./Menu";

const Projects = () => {
  return (
    <div className="fc jc aic projectsDiv">
      <Menu />
      <div className="fc fdv aic projectsContentDiv">
        {/* <div className="fc jc aic stackSelectDiv">
          <select name="stack" id="stack" className="stackSelect">
            <option value="all">All Stacks</option>
            <option value="mern">MERN</option>
            <option value="react_native">React Native</option>
            <option value="django">Django</option>
            <option value="java">Java</option>
            <option value=".Net">NET</option>
          </select>
        </div> */}
        <div className="fc jc aic projectsContentDiv1">
          <div className="fc fdv aic projectCardDiv w3-card-4">
            <div className="fc fdv jsa aic projectCard w3-hover-grayscale w3-animate-zoom w3-card-4">
              <h3>ILearnSTEM</h3>
              <div className="fc jc aic projectCardImageDiv">
                <img
                  src="https://ik.imagekit.io/0x95zrerf/teye-brown/ilearnSTEM_compressed.svg?updatedAt=1722470777731"
                  className="w3-image projectCardImage"
                  alt="ILearnSTEM"
                />
              </div>
              <div className="fc fdv aic projectCardDescriptionDiv">
                <div className="fc jc aic tags">
                  <span className="tag">MERN</span>
                  <span className="tag">Edtech</span>
                  <span className="tag">STEM</span>
                </div>
                <div className="fc jc aic tags">
                  <span className="tag">Redux</span>
                  <span className="tag">K-12</span>
                </div>
              </div>
              <div className="fc jc aic projectCardFooterDiv">
                <button className="w3-button projectCardButton">
                  <a
                    href="https://www.ilearnstem.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Project
                  </a>
                </button>
              </div>
            </div>
            <div className="fc jc aic projectCardParagraph">
              <p>
                iLearnSTEM is dedicated to transforming students' performance in
                Physics, Chemistry, and Mathematics by harnessing the power of
                Artificial Intelligence. Our platform delivers personalized,
                adaptive content tailored to each student's learning level,
                ensuring a truly customized educational experience.
              </p>
            </div>
          </div>
          <div className="fc fdv aic projectCardDiv w3-card-4">
            <div className="fc fdv jsa aic projectCard w3-hover-grayscale w3-animate-zoom">
              <h3>ILearnSTEMCards</h3>
              <div className="fc jc aic projectCardImageDiv">
                <img
                  src="https://ik.imagekit.io/0x95zrerf/teye-brown/ilearnSTEMCards%201.svg?updatedAt=1722303307733"
                  className="w3-image projectCardImage"
                  alt="ILearnSTEMCards"
                />
              </div>
              <div className="fc fdv aic projectCardDescriptionDiv">
                <div className="fc jc aic tags">
                  <span className="tag">MERN</span>
                  <span className="tag">Edtech</span>
                  <span className="tag">STEM</span>
                </div>
                <div className="fc jc aic tags">
                  <span className="tag">socket.io</span>
                  <span className="tag">webRTC</span>
                  <span className="tag">Competitions</span>
                </div>
              </div>
              <div className="fc jc aic projectCardFooterDiv">
                <button className="w3-button projectCardButton">
                  <a
                    href="https://www.ilearnstemcards.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Project
                  </a>
                </button>
              </div>
            </div>
            <div className="fc jc aic projectCardParagraph">
              <p>
                ilearnSTEMcards is a dynamic platform where learners can create
                and share study flashcards, collaborate in live study rooms, and
                compete in global quizzes. Join a global community of passionate
                learners, enhance your skills, and rise in the rankings as you
                connect with like-minded peers worldwide.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
