import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toggleDropDown } from "../utils";
import Menu from "./Menu";

const Teye = () => {
  // const navigate = useNavigate();

  const statement =
    "An Experienced Full Stack Developer, Passionate and Experienced STEM Instructor & Assistant Professor, " +
    " and co-Author.";

  // const showDropDownMenu = () => {
  //   var x = document.getElementById("dropDownMenu");
  //   if (x.className.indexOf(" w3-show") === -1) {
  //     x.className += " w3-show";
  //   } else {
  //     x.className = x.className.replace(" w3-show", " ");
  //   }
  // };

  useEffect(() => {
    var id;
    var opId;
    //document.getElementById("teyePic").style.display="none";
    function showText(elem_id, fullText, time = 70) {
      //elem_id = id of element (div)
      var elem = document.getElementById(elem_id);
      var length = fullText.length;
      elem.innerHTML = "";

      if (elem_id === "profileSummary") id = setInterval(frame1, time);
      else id = setInterval(frame2, time);
      var i = 0;

      function frame1() {
        if (i >= length) {
          clearInterval(id);
        } else {
          elem.innerHTML = fullText.substring(0, i);
          i++;
        }
      }

      function frame2() {
        if (i >= length) {
          clearInterval(id);
        } else {
          elem.innerHTML += fullText.charAt(i);
          i++;
        }
      }
    }

    function animateOpacity(elem_id, max) {
      //elem_id = id of element (div)
      var elem = document.getElementById(elem_id);

      var opacity = 0;

      opId = setInterval(frame, 200);

      function frame() {
        if (opacity >= max) {
          clearInterval(opId);
        } else {
          opacity++;
          elem.style.opacity = opacity + "%";
        }
      }
    }

    showText("profileSummary", statement);
    animateOpacity("teyePic", 100);
    showText("signature", "Dr. John Teye Brown", 400);

    return () => {
      clearInterval(id);
      clearInterval(opId);
    };
  });

  return (
    <div id="mainDiv">
      <div id="top_div">        
        <div id="dropDownMenu" className="fc fdv aic w3-hide w3-hide-large">
          <div className="dropDownButtonDiv">
            <Link to="/cv" style={{ textDecoration: "none" }}>
              <span className="w3-button">CV</span>
            </Link>
          </div>
          <div className="dropDownButtonDiv">
            <Link to="/projects" style={{ textDecoration: "none" }}>
              <span className="w3-button">Projects</span>
            </Link>
          </div>
          {/* <div className="dropDownButtonDiv">
            <Link
              to="/blog"
              state={{ from: "/" }}
              style={{ textDecoration: "none" }}
            >
              <span
                className="w3-button"
                onClick={() => {
                  document.getElementById("future").style.display = "block";
                }}
              >
                Blog
              </span>
            </Link>
          </div> */}
          {/* <div className="dropDownButtonDiv">
            <Link
              to="/vlog"
              state={{ from: "/" }}
              style={{ textDecoration: "none" }}
            >
              <span className="w3-button">Vlog</span>
            </Link>
          </div> */}
          <div className="dropDownButtonDiv">
            <Link
              to="/contact"
              state={{ from: "/" }}
              style={{ textDecoration: "none" }}
            >
              <span
                className="w3-button"
                onClick={() => toggleDropDown("dropDownMenu")}
              >
                Contact
              </span>
            </Link>
          </div>
          <div className="dropDownButtonDiv">
            <Link
              to="/schedule"
              state={{ from: "/" }}
              style={{ textDecoration: "none" }}
            >
              <span className="w3-button">Schedule</span>
            </Link>
          </div>
        </div>
        <div className="fc heroAndProfileSummaryDiv">
          <div className="fc aic heroImageDiv">
            <img
              className="heroImage w3-image"
              src="https://ik.imagekit.io/0x95zrerf/teye-brown/teyeImage.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1672022178293"
              alt="teye"
              id="teyePic"
              style={{ opacity: 0 }}
            />
          </div>

          <div className="fc fdv jsa aic profileAndSignatureDiv">
            <div className="nameAndCredentialsDiv">
              <div className="name">
                John Teye Brown, <span className="credential1">PhD</span>
              </div>
              <p className="credential2">PhD, Msc, MSc, BSc</p>
            </div>
            <p id="profileSummary"></p>
            <p className="w3-text-sepia" id="signature"></p>
            <div className="fc jc">
              <Link to="/projects">
                <button
                  className="w3-button w3-round w3-hover-sepia"
                  id="exploreMoreButton"
                >
                  Explore More!
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>{" "}
      <Menu />
    </div>
  );
};
export default Teye;
